import React from "react"

export const Dovile = (
  <>
    Had an excellent experience. Very professional and friendly photographer. She puts so much
    effort in every photo she takes. Thank you for capturing so many amazing moments of our wedding
    day. Highly recommend!!
  </>
)
export const Carly = (
  <>
    I highly recommend this lady and her daughter, they made us feel welcome and had some great
    photos taken. Such a lovely warm and fun atmosphere, also a big thankyou to how you was with our
    baby, she was so settled. Definatly going back xx
  </>
)
export const Ale = (
  <>
    Firstly we had so much fun,and felt very comfortable.Its been a lovely experience,very
    professional and warm,looking forward to our next photo shoot with the family’s new addition ❤️
  </>
)
export const Jurga = (
  <>
    Amazing photographer with amazing skills. Truely loved every bit of her work. Very nice and worm
    person ✌😊
  </>
)
export const Sue = <>Very professional and brilliant with my twin grandchildren</>
export const Sky = (
  <>
    such a amazing photo shoot my 3 year old was very cheeky and vita was so patient and took her
    time to get the perfect picture's really so pleased. highly recommend you Will not be
    disappointed thank you so much xxx
  </>
)
export const Dan = (
  <>
    fantastic work, would recommend to everyone, really nice person and makes you feel very welcome
    and pictures are amazing thank you 😁
  </>
)
export const Eva = (
  <>Brilliant photographer, we had fun and I love her work. I recommend her 💯 😘</>
)
