import React from "react"
const portrait = (
  <>
    Portrait Photography is a photograph of a person or group of people that captures the
    personality of the subject by using effective lighting, backdrops, and poses. Which I strive to
    achieve with my many years of experience and my professional grade equipment. Portrait
    photography can open the door to a better understanding of the person's character. You would be
    able to express yourself with your full potential at one of my portrait photo shoots.
  </>
)
export default portrait
