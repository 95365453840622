import React from "react"
const party = (
  <>
    The Joy of partying with your friends, family or colleagues is an outstanding experience. You
    can save those amazing times with my Party photo shoots. That capture the liveliness and the
    rush of the party. Parties are a lot easier to Photograph because they are naturally full of
    life and happiness. I just need to find the right angles and shoot. My approach to party
    photography is to make expressive photographs at the same time not to get in the way of the
    party.
  </>
)
export default party
