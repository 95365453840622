import React from "react"

const Weddings = (
  <>
    Your Wedding is one of the most exiting , joyful and memorable moments of your life. With my
    Wedding Photography photo shoots you will be able to store those memories forever. Preparing for
    the wedding is hard work you don't need to worry about photography, just leave it all to me. My
    ability to capture most enticing shot amazes everyone. I make sure to make any posing directions
    quick and clear to understand. Finding the right backgrounds to pose next to is a difficult
    challenge, but with all the experience in photography I manage it with ease. And even when it
    takes all day I do not tire to bring the best photographs that you deserve for one of the most
    wonderful times of your life.
  </>
)

export default Weddings
