import React from "react"
const event = (
  <>
    Whatever the Event could be I'm always ready to capture every unique aspect of it. The event is
    a vast concept so it is hard to evaluate how much work it would take to photograph. I have a
    rough estimate in the pricing section. Although the price may vary slightly for different types
    of events.
  </>
)
export default event
